$wrapper-width-lg: 1023px;

$initial-font-size: 18px;

$font-color-white: #fff;
$font-color-black: #000;
$font-color-grey: #6d748b;
$font-color-yellow: #ffc910;

$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$color-secondary: #ffc910;
$color-white: #fff;

$shadow-color-light: rgba(0, 0, 0, 0.1);

$button-border-radius: 5px;

$max-z-index: 999;

$screen-xl: 1600px;
$screen-l: 1200px;
$screen-m: 1000px;
$screen-s: 650px;
$screen-xs: 500px;

$spinner-color: rgba(65, 65, 65, 0.9);

$sidebar-width: 348px;
$sidebar-width-mobile: 300px;
$sidebar-background-color: #F7F9FA;

$sidebar-mobile-header-background-color: #F7F9FA;
$sidebar-mobile-header-height: 70px;

$sidebar-menu-item-color: #333;

$docs-font-size: 17px;
$docs-font-size-mobile: 16px;

$docs-badge-text-color: #ffffff;

$docs-badge-secondary-background-color: #eeeff0;
$docs-badge-secondary-text-color: #333;
$docs-badge-secondary-border-color: #d9d9d9;

$docs-table-border-color: #d9d9d9;

$docs-modal-background-color: #ffffff;
$docs-modal-overlay-background-color: #3d424e;
