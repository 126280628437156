@import "variables";

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Lato-400.woff2') format('woff2'),
  url('./fonts/Lato-400.ttf') format('truetype'),
  url('./fonts/Lato-400.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Lato-600.woff2') format('woff2'),
  url('./fonts/Lato-600.ttf') format('truetype'),
  url('./fonts/Lato-600.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Lato-700.woff2') format('woff2'),
  url('./fonts/Lato-700.ttf') format('truetype'),
  url('./fonts/Lato-700.woff') format('woff');
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

  * {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;

  }
}


.segment {
  padding-left: 15% !important;
  padding-right: 15% !important;
  position: relative !important;
  left: 0 !important;
}

@media screen and (max-width: $screen-l) {
  .segment {
    padding-left: 8em !important;
    padding-right: 8em !important;
  }
}

@media screen and (max-width: $screen-m) {
  .segment {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }
}

@media screen and (max-width: $screen-s) {
  .segment {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}

@media screen and (max-width: $screen-xs) {
  .segment {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}
